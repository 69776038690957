import React from "react";
import { Link} from "react-router-dom";
import styles from './Navbar.module.css'

export default function Navbar() {

  return (
    <div className={styles.wrapper}>
      <Link className={styles.link} to='/'><strong>Furry Art - Pet Name Generator</strong></Link>
    </div>
  )
}