import React, { useEffect, useState } from 'react'
import { baseUrl } from '../config';
import axios from 'axios'
import styles from './Home.module.css'
// import { NavHashLink } from 'react-router-hash-link';
import BadWordsFilter from 'bad-words';
import { Helmet } from 'react-helmet';
import image from '../assets/aiPetPhotos.com-8-2.jpg'
import { Link } from "react-router-dom"

export default function Home() {
  const [button, setButton] = useState('Generate')

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, []);
  const filter = new BadWordsFilter();

  const [error, setError] = useState('');
  const [names, setNames] = useState([]);
  const [groupedNames, setGroupedNames] = useState({});



  const [userInput, setUserInput] = useState({
    message: '',
    temperature: 0.2,
    tokens: 100,
    sysMessageInput: 'Use object format with the keys category and names; category just includes the theme. 5 names total. Dont explain what you are sending.',
  })

  const [output, setOutput] = useState({
    category: '',
    names: []
  })

  function removeDuplicates(petNames) {
    const seenNames = new Set(); // A set to keep track of all names we've seen

    petNames.forEach(category => {
      category.names = category.names.filter(name => {
        if (!seenNames.has(name)) {
          seenNames.add(name);
          return true;
        }
        return false;
      });
    });
    return petNames;
  }

  const groupByCategory = (namesArray) => {
    const grouped = {};
    namesArray.forEach(item => {
      // Convert the category to lower case for case-insensitive grouping
      const category = item.category?.toLowerCase();
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category] = [...grouped[category], ...item.names];
    });
    return grouped;
  };


  useEffect(() => {
    async function getNames() {
      try {
        const response = await axios.get(`${baseUrl}/names-others`)
        // console.log("RESPONSE DATA", response.data);

        if (response.data.length > 0) {
          // setNames(response.data)
          const cleanedNames = removeDuplicates(response.data);
          console.log(response.data);

          const grouped = groupByCategory(cleanedNames);
          setNames(cleanedNames);
          setGroupedNames(grouped);
        }

      } catch (e) {
        setError(e.message)
      }
    }

    getNames()
  }, [])



  function filterCatInput(input) {
    if (filter.isProfane(input)) {
      return false; // or return filter.clean(name) to censor the inappropriate content
    }
    return true
  }

  function validate() {
    if (userInput.message.length < 1) {
      setError("Please enter a theme.")
      setButton('Generate')
      return false
    }
    const filterCat = filterCatInput(userInput.message)
    if (!filterCat) {
      setError('Bad words detected, please adjust your input.')
      setButton('Generate')
      return false
    }
    return true
  }


  async function submitMessage() {
    setButton('Generating..')
    setError("")


    try {
      let input = userInput;

      const validInput = validate();
      if (!validInput) return

      if (!input.message.includes('names')) {
        input.message = input.message + ' names'
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseUrl}/names`, userInput)

      if (!response.data) {
        setError('No data from server, please contact support')
        return
      } else {

        setUserInput({
          message: '',
          temperature: 0.2,
          tokens: 100,
          sysMessageInput: 'Use object format with the keys category and names; category just includes the theme. 6 names total. Dont explain what you are sending.',
        })

        setOutput({
          category: response.data.category,
          names: response.data.names
        })
        setButton('Generate')
        setError('')
        return
      }
    } catch (e) {
      setError(e.message || "An error occurred"); // Use the error's message property
    }
  }



  return (
    <div className={styles.wrap}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Furry Art - Free Pet Name Generator with AI - Generate Cute Pet Names, Unique Pet Names with AI for free!</title>

        <meta name="description" content='AI Pet Generator - Generate new funny and unique pet names, using our new free pet name generator. Apart of our furry art services, get cute pet names, unique pet names, calico pet names, tuxedo pet names anime pet names, all generated with ai and browse though thousands more.' />
        <link rel="canonical" href='https://furry-art.com/' />

        <meta property="og:title" content={`Furry Art - Free Pet Name Generator with AI - Generate Cute Pet Names, Unique Pet Names with AI for free!`} />
        <meta property="og:url" content='https://furry-art.com/' />

        <meta property="og:description" content='Generate new funny and unique pet names, using our new free pet name generator. Apart of our furry art services, get cute pet names, unique pet names, calico pet names, tuxedo pet names anime pet names, all generated with ai and browse though thousands more.' />
        <meta property="twitter:description" content='Generate new funny and unique pet names, using our new free pet name generator. Apart of our furry art services, get cute pet names, unique pet names, calico pet names, tuxedo pet names anime pet names, all generated with ai and browse though thousands more.' />
      </Helmet>


      <h1>Furry Art - Free Pet Name Generator with AI</h1>
      <h2>Naming a new pet? Easier now with AI pet name generator!</h2>
      <p>Generate the perfect name for your pet with our AI-driven Pet Name Generator. <br />
        From cute to unique, find inspiration and instantly create a name that matches your pet's personality.</p>
      <small><strong>created by <a href='https://aipetphotos.com'>AiPetPhotos.com</a> & AI</strong></small>


      <div
        className={styles.uiWrapper}
        style={{
          display: 'flex',
        }}>


        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>

          <label className={styles.inputLabel}>
            Enter Theme & Pet:
            <input type='text'
              className={styles.genInput}
              value={userInput.message}
              maxLength="30"
              placeholder='e.g. Sci-fi parrot'
              onChange={(e) => setUserInput(prev => {
                return { ...prev, message: e.target.value }
              })} />



            <button
              disabled={button !== 'Generate'}
              className={styles.submitMessage}
              onClick={submitMessage}>{button}</button>
            <br />
            <small>{userInput.message && `Entered ${userInput.message.length} characters.`} Max characters: 30. </small>
            <br />
            <br />
            <label>Creativity: {(1 - userInput.temperature).toFixed(1) * 10}
              <br />0
              <input type="range" min="0.00" max="1" step="0.05"
                onChange={(e) => {
                  e.preventDefault()
                  setUserInput((prev) => {
                    return { ...prev, temperature: 1 - e.target.value }
                  })
                }}
                value={(1 - userInput.temperature)} />
              10
            </label >
            <br />

            {error && <p>{error}</p>}

          </label>


          <small>AI Image Generator {" "}
            <a href='https:/aipetphotos.com.com/' style={{ color: 'blue' }}>
              for pets
            </a></small>




          <div className={styles.output}>
            {output.names.length > 0 ?
              <div className={styles.outWrap}>
                <p>Your {output.category}</p>
                <ol>
                  {output.names.length > 0 && output.names.map((name, index) => {
                    return (
                      <li key={index}>{name}</li>
                    )
                  }
                  )}
                </ol>
                <small>Your names will be added to the public directory.</small>

              </div>
              : <p className={styles.outWrap}><b>Your names: </b>Awaiting your theme input</p>}
          </div>
        </div>

        <div>
          <a href='https://aipetphotos.com/'
            className={styles.bannerWrap}>
            <img className={styles.banner}
              src={image}
              alt='ai generated pet' />
            <br />
            <strong>AI Image Generator
              for pets</strong><br />
            AiPetPhotos.com #furryArt
          </a>
        </div>
      </div>
      <h2>Existing Categories</h2>
      <small>Tap a Category to see exisitng names</small>
      <div className={styles.catButtons}>

        {Object.keys(groupedNames).length > 0 &&
          Object.keys(groupedNames).map((category, index) => {
            const encodedString = category.replace(/\s+/g, '-');
            const number = groupedNames[category].length
            return (
              <Link
                // smooth
                key={index}
                to={`/pet-name-category/${encodedString}`}
                className={styles.gotoCat}>
                {category.slice(0, 1).toUpperCase() + category.slice(1)} ({number})
              </Link>
            )
          })}
      </div>




      <p>Got a name? Awesome! Now let's make some art, Furry Art:</p>


      <a href='https://aipetphotos.com/'
        className={styles.bannerWrap}>
        <img className={styles.banner} src={image}
          alt='cat ai portrait by AiPetPhotos.com' />
        <br />
        <strong>AI Image Generator
          For Pets</strong><br />
        AiPetPhotos.com #furryArt
      </a>
      <a href='https://aipetphotos.com/'
        className={styles.callAction}>
        Checkout the AI Image Generator for Pets
      </a>



      <li><a href='https://aicatz.com' className={styles.LinkComp}>Free Cat Name Generator - AiCatz &#8599;</a></li>
      <li><a href='https://ai-dog-generator.com/' className={styles.LinkComp}>Free Dog Name Generator &#8599;</a></li>
    </div >
  )
}