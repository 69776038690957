import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './comp/Home.js'
// import Jokes from './ui/Jokes.js';
import NameCategory from './comp/NameCategory.js';
import Navbar from './ui/Navbar.js'
import UIGen from './comp/UIGen.js'

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Navbar />
        </header>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/pet-name-category/:categoryName' element={<NameCategory />} />
          <Route path='/uigen' element={<UIGen />} />

          {/* <Route path='/joke-generator' element={<Jokes />} /> */}

        </Routes>
      </Router>
    </div>
  );
}

export default App;
