import React, { useEffect, useState } from 'react'
import { baseUrl } from '../config';
import axios from 'axios'
import styles from './UIGen.module.css'
import BadWordsFilter from 'bad-words';
import styled from 'styled-components';

export default function UIGen() {


  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, []);

  const [button, setButton] = useState('Generate UI')
  const [error, setError] = useState('');
  const filter = new BadWordsFilter();


  const [userInput, setUserInput] = useState({
    message: 'batman linear gradient action button',
    temperature: 0.5,
    tokens: 300,
    sysMessageInput: 'Only provide the css. Do NOT explain what you are sending or what the code does! No transition, no hover! No classname',
  })

  const [output, setOutput] = useState({
    message: '',
    category: '',
    type: '',
    css: {},
    response: ''
  })



  function filterUserInput(input) {
    if (filter.isProfane(input)) {
      return false; // or return filter.clean(name) to censor the inappropriate content
    }
    return true
  }

  function validate() {
    if (userInput.message.length < 1) {
      setError("Please enter a prompt.")
      setButton('Generate UI')
      return false
    }
    const filterCat = filterUserInput(userInput.message)
    if (!filterCat) {
      setError('Bad words detected, please adjust your input.')
      setButton('Generate UI')
      return false
    }
    return true
  }

  async function submitMessage() {
    setButton('Generating..')
    setError("")


    try {
      const validInput = validate();
      if (!validInput) return

      const token = localStorage.getItem('token');

      const response = await axios.post(`${baseUrl}/uigen`, userInput)
      console.log(response);

      if (!response.data) {
        setError('No data from server, please contact support')
        return
      } else {




        let content = response.data.css;
        console.log('raw data from API', content);


        const cleanCssString = content.replace(/```css|```/g, '').trim();


        setOutput(prev => {
          return {
            ...prev,
            message: userInput.message,
            response: cleanCssString,
            css: cleanCssString,
          }
        })

        setUserInput({
          message: 'batman linear gradient action button',
          temperature: 0.5,
          tokens: 300,
          sysMessageInput: 'Only provide the css. Do NOT explain what you are sending or what the code does! No transition, no hover! No classname.',
        })

        setButton('Generate UI')
        setError('')
        return
      }
    } catch (e) {
      setError(e.message || "An error occurred"); // Use the error's message property
      setButton('Generate UI')
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(output.response);
  };

  const StyledButton = styled.button`${props => props.styles}`;

  return (
    <div
      className={styles.uiWrapper}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>


      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>


        <label className={styles.inputLabel}>
          Enter theme & type to get CSS from AI
          <br />
          <input type='text'
            className={styles.genInput}
            value={userInput.message}
            maxLength="50"
            placeholder='e.g. sci-fi action button'
            onChange={(e) => setUserInput(prev => {
              return { ...prev, message: e.target.value }
            })} />


          <input type="number"
            value={userInput.tokens}
            onChange={(e) => setUserInput(prev => {
              return { ...prev, tokens: e.target.value }
            })}
          />
          <input type="number"
            value={userInput.temperature}
            onChange={(e) => setUserInput(prev => {
              return { ...prev, temperature: e.target.value }
            })}
          />
        </label>

        <button
          disabled={button !== 'Generate UI'}
          className={styles.submitMessage}
          onClick={submitMessage}>{button}</button>

        <br />
        {error && <p>{error}</p>}
        <small>{userInput.message && `Entered ${userInput.message.length} characters.`} Max characters: 50. </small>

      </div>

      {output.response &&
        <div>
          <h3>Output</h3>
          <p>{output.message}</p>

          <pre>
            <code>{output.response}</code>

          </pre>
          <button onClick={copyToClipboard}>Copy CSS</button>
          <br />
          <br />

          {/* <button>Edit</button> */}

          {/* <button className={styles.styledButton}>Test Button</button> */}
          <StyledButton styles={output.css}>Your button</StyledButton>

        </div>
      }
    </div >
  )
}